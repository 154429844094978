import React, { useState } from 'react';
import './App.css';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    message: '',
  });
  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        setFormStatus('Message sent successfully!');
      } else {
        setFormStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      setFormStatus('Error: ' + error.message);
    }
  };

  return (
    <div className="App">
      {/* Header Bar */}
      <header className="header-bar">
        <h1 className="text-logo">neat.</h1>
      </header>

      {/* Original Hero Section */}
      <section className="section hero">
        <div className="section-split">
          <div className="text-content hero">
            <h1>Unlock global growth</h1>
            <p>We help small businesses navigate the complexities of launching in Israel or abroad. From regulations to market strategy, we guide your product to success.</p>
            <a href="#contact" className="cta-button">Let’s talk</a>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="section about">
        <div className="section-split reverse">
          <div className="text-content about">
            <h2>Tailored solutions</h2>
            <p>No one-size-fits-all here. We create custom strategies—market research, regulatory insights, sales planning—that align with your business goals.</p>
          </div>
          <div className="graphic-content-hero">
            <div className="graphic-card-hero">
              <img src="/pmf.png" alt="Graphic" className="hero-graphic" />
            </div>
          </div>
        </div>
      </section>

      {/* Clients Section */}
      <section className="section clients" id="clients">
        <div className="section-split">
          <div className="text-content">
            <h2>Industry innovators</h2>
            <p>We’re in it for the long haul. As your brand grows, we’re there to ensure you adapt and thrive in new markets.</p>
          </div>
          <div className="clients-grid">
            <div className="graphic-card">
              <img src="logo-mills.svg" alt="Client Logo" className="clients-graphic" />
            </div>
            <div className="graphic-card">
              <img src="acelogo.png" alt="Client Logo" className="clients-graphic" />
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="section services" id="services">
        <div className="section-split">
          <div className="service-list">
            <ul>
              <li><span className="material-icons">search</span> Market Research & Analysis</li>
              <li><span className="material-icons">campaign</span> Brand Strategy & Positioning</li>
              <li><span className="material-icons">groups</span> Local Representation & Partner Network</li>
              <li><span className="material-icons">launch</span> Product-Market Fit Solutions</li>
              <li><span className="material-icons">gavel</span> Regulatory Compliance</li>
              <li><span className="material-icons">trending_up</span> Sales & Marketing</li>
            </ul>
          </div>
          <div className="text-content">
            <h2>Real support for real growth</h2>
            <p>From insights to action, we’re with you every step of the way.</p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="section contact" id="contact">
        <div className="contact-content">
          <h2>Ready to Grow Your Brand?</h2>
          <p>Let's create a tailored strategy to help your brand thrive in new markets</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="company"
              placeholder="Your Company"
              value={formData.company}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="cta-button">Send message</button>
          </form>
          {formStatus && <p>{formStatus}</p>}
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer-bar">
        <p>© 2024 Neat Brands Inc. | Tel Aviv, Israel | +972.53.331.3169</p>
      </footer>
    </div>
  );
}

export default App;
